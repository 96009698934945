import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import 'primereact/resources/primereact.min.css'
import 'react-quill/dist/quill.snow.css'
import './scss/App.scss'

import { Toast } from 'primereact/toast'
import { getMyself } from './api'
import { useGlobalContext } from './context'

import AppProfile from './app/AppProfile'
import AppTopbar from './app/AppTopbar'
import menuTemplate from './data/menu'

import PrimeReact from 'primereact/api'
import AppMenu from './app/AppMenu'
import CustomerEnquiryPage from './pages/CustomerManagementPages/CustomerEnquiryPage'
import AssignOrderPage from './pages/OrderManagementPages/AssignOrderPage'
import BinOrdersPage from './pages/OrderManagementPages/BinOrdersPage'
import OrdersPage from './pages/OrderManagementPages/OrdersPage'
import DashboardPage from './pages/OtherPages/DashboardPage'
import EmptyPage from './pages/OtherPages/EmptyPage'
import ActivitiesPage from './pages/UserManagementPages/ActivitiesPage'
import CartPage from './pages/WebsiteManagementPages/CartPage'
import CategoryPage from './pages/WebsiteManagementPages/CategoryPage'
import CouponsPage from './pages/WebsiteManagementPages/CouponsPage'
import DiscountsPage from './pages/WebsiteManagementPages/DiscountsPage'
import FaqsPage from './pages/WebsiteManagementPages/FaqsPage'
import ProductsPage from './pages/WebsiteManagementPages/ProductsPage'

import ManualOrdersPage from './pages/OrderManagementPages/ManualOrdersPage'
import OrderApprovalRequestsPage from './pages/OrderManagementPages/OrderProcessedRequestPage'
import IpAccessPage from './pages/UserManagementPages/IpAccessPage'
import LogisticPage from './pages/WebsiteManagementPages/LogisticPage'
import OffersPage from './pages/WebsiteManagementPages/OffersPage'
// import CallHippoDialer from './app/CallHippoDialer'
import CustomerBlacklistPage from './pages/CustomerManagementPages/CustomerBlacklistPage'
import CustomerDND from './pages/CustomerManagementPages/CustomerDNDPage'
import ComplaintAndCasesPage from './pages/IssueManagementPages/ComplaintAndCasesPage'
import DelayedOrdersPage from './pages/IssueManagementPages/DelayedOrdersPage'
import DisputeAndChargebackPage from './pages/IssueManagementPages/DisputeAndChargebackPage'
import UserTicketManagementPage from './pages/IssueManagementPages/UserTicketManagementPage'
import MyOrdersPage from './pages/OrderManagementPages/MyOrdersPage'
import MyTeamOrdersPage from './pages/OrderManagementPages/MyTeamOrdersPage'
import OrderContactFixPage from './pages/OrderManagementPages/OrderContactFixPage'
import TargetsPage from './pages/TargetManagmentPages/TargetsPage'
import AffiliatePage from './pages/UserManagementPages/AffiliatePage'
import TeamsPage from './pages/UserManagementPages/TeamsPage'
import UsersPage from './pages/UserManagementPages/UsersPage'
import BannersPage from './pages/WebsiteManagementPages/BannersPage'
import CategoryArangePage from './pages/WebsiteManagementPages/CategoryArangePage'
import LocationWhitelistPage from './pages/CustomerManagementPages/LocationWhitelistPage'
import OrderIssueFaqsPage from './pages/WebsiteManagementPages/OrderIssueFaqsPage'
import PaymentMethodsPage from './pages/WebsiteManagementPages/PaymentMethodsPage'
import ProductPricePage from './pages/WebsiteManagementPages/ProductPricePage'
import ReviewsPage from './pages/WebsiteManagementPages/ReviewsPage'
import WebsiteDashboardPage from './pages/WebsiteManagementPages/WebsiteDashboardPage'
import UndeliveredOrdersPage from './pages/OrderManagementPages/UndeliveredOrdersPage'
import AutoCartPage from './pages/CustomerManagementPages/AutoCartPage'
import CustomerPage from './pages/CustomerManagementPages/CustomerPage'

const App = (props) => {
  const { callHippoDialerVisible, setCallHippoDialerVisible, setCallHippoDialerUrl } =
    useGlobalContext()
  const {
    setUser,
    user,
    fetchCategories,
    fetchTags,
    fetchTeams,
    fetchProductNames,
    fetchProductStrengths,
    fetchAgents,
    fetchAllWebsites,
    fetchDashWebsites,
    fetchPaymentMethods,
    fetchConfig,
  } = useGlobalContext()

  const menu = menuTemplate(user)
  const [rightMenuActive, setRightMenuActive] = useState(false)
  const [configActive, setConfigActive] = useState(false)
  const [menuMode, setMenuMode] = useState('sidebar')
  const [overlayMenuActive, setOverlayMenuActive] = useState(false)
  const [ripple, setRipple] = useState(true)
  const [sidebarStatic, setSidebarStatic] = useState(false)
  const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false)
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false)
  const [menuActive, setMenuActive] = useState(false)
  const [searchActive, setSearchActive] = useState(false)
  const [topbarMenuActive, setTopbarMenuActive] = useState(false)
  const [sidebarActive, setSidebarActive] = useState(false)
  const [pinActive, setPinActive] = useState(false)
  const [activeInlineProfile, setActiveInlineProfile] = useState(false)
  const [resetActiveIndex, setResetActiveIndex] = useState(null)
  const authToken = localStorage.getItem('token')
  PrimeReact.ripple = true

  let rightMenuClick
  let configClick
  let callHippoDialerClick
  let menuClick
  let searchClick = false
  let topbarItemClick

  useEffect(() => {
    setResetActiveIndex(true)
    setMenuActive(false)
    const fetchMyData = async () => {
      if (localStorage.getItem('token')) {
        const res = await getMyself()
        if (res) {
          if (res.status === 200) {
            const user = res?.data
            setUser((ps) => ({ ...ps, ...user }))
            await Promise.all([
              fetchCategories(),
              fetchTags(),
              fetchProductNames(),
              fetchProductStrengths(),
              fetchAgents(),
              fetchTeams(),
              fetchAllWebsites(),
              fetchDashWebsites(),
              fetchPaymentMethods(),
              fetchConfig(),
            ])
          }
        }
      }
    }
    fetchMyData()
  }, [])

  const onDocumentClick = () => {
    if (!searchClick && searchActive) {
      onSearchHide()
    }

    if (!topbarItemClick) {
      setTopbarMenuActive(false)
    }

    if (!menuClick) {
      if (isHorizontal() || isSlim()) {
        setMenuActive(false)
        setResetActiveIndex(true)
      }

      if (overlayMenuActive || staticMenuMobileActive) {
        setOverlayMenuActive(false)
        setStaticMenuMobileActive(false)
      }

      hideOverlayMenu()
      unblockBodyScroll()
    }

    if (!rightMenuClick) {
      setRightMenuActive(false)
    }

    if (configActive && !configClick) {
      setConfigActive(false)
    }
    if (callHippoDialerVisible && !callHippoDialerClick) {
      setCallHippoDialerUrl('https://dialer.callhippo.com/dial')
      setCallHippoDialerVisible(false)
    }

    topbarItemClick = false
    menuClick = false
    configClick = false
    callHippoDialerClick = false
    rightMenuClick = false
    searchClick = false
  }

  const onSearchHide = () => {
    setSearchActive(false)
    searchClick = false
  }

  const onMenuModeChange = (menuMode) => {
    setMenuMode(menuMode)
    setOverlayMenuActive(false)
  }

  const onRightMenuButtonClick = () => {
    rightMenuClick = true
    setRightMenuActive(true)
  }

  const onRightMenuClick = () => {
    rightMenuClick = true
  }

  const onRightMenuActiveChange = (active) => {
    setRightMenuActive(active)
  }

  const onConfigClick = () => {
    configClick = true
  }

  // const onCallHippoDialerClick = () => {
  //   callHippoDialerClick = true
  // }

  const onConfigButtonClick = (event) => {
    setConfigActive((prevState) => !prevState)
    configClick = true
    event.preventDefault()
  }

  // const onCallHippoDialerButtonClick = (event) => {
  //   setCallHippoDialerVisible((prevState) => !prevState)
  //   callHippoDialerClick = true
  //   event.preventDefault()
  // }

  const onRippleChange = (e) => {
    PrimeReact.ripple = e.value
    setRipple(e.value)
  }

  const onMenuButtonClick = (event) => {
    menuClick = true

    if (isOverlay()) {
      setOverlayMenuActive((prevState) => !prevState)
    }

    if (isDesktop()) {
      setStaticMenuDesktopInactive((prevState) => !prevState)
    } else {
      setStaticMenuMobileActive((prevState) => !prevState)
    }

    event.preventDefault()
  }

  const hideOverlayMenu = () => {
    setOverlayMenuActive(false)
    setStaticMenuMobileActive(false)
  }

  const onTopbarItemClick = (event) => {
    topbarItemClick = true
    setTopbarMenuActive((prevState) => !prevState)
    hideOverlayMenu()
    event.preventDefault()
  }

  const onToggleMenu = (event) => {
    menuClick = true

    if (overlayMenuActive) {
      setOverlayMenuActive(false)
    }

    if (sidebarActive) {
      setSidebarStatic((prevState) => !prevState)
    }

    event.preventDefault()
  }

  const onSidebarMouseOver = () => {
    if (menuMode === 'sidebar' && !sidebarStatic) {
      setSidebarActive(isDesktop())
      setTimeout(() => {
        setPinActive(isDesktop())
      }, 200)
    }
  }

  const onSidebarMouseLeave = () => {
    if (menuMode === 'sidebar' && !sidebarStatic) {
      setTimeout(() => {
        setSidebarActive(false)
        setPinActive(false)
      }, 250)
    }
  }

  const onMenuClick = () => {
    menuClick = true
  }

  const onChangeActiveInlineMenu = (event) => {
    setActiveInlineProfile((prevState) => !prevState)
    event.preventDefault()
  }

  const onRootMenuItemClick = () => {
    setMenuActive((prevState) => !prevState)
  }

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      hideOverlayMenu()
      setResetActiveIndex(true)
    }

    if (!event.item.items && (isHorizontal() || isSlim())) {
      setMenuActive(false)
    }
  }

  const isHorizontal = () => {
    return menuMode === 'horizontal'
  }

  const isSlim = () => {
    return menuMode === 'slim'
  }

  const isOverlay = () => {
    return menuMode === 'overlay'
  }

  const isDesktop = () => {
    return window.innerWidth > 991
  }

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll')
    } else {
      document.body.className = document.body.className.replace(
        new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'),
        ' '
      )
    }
  }

  const layoutClassName = classNames('layout-wrapper', {
    'layout-static': menuMode === 'static',
    'layout-overlay': menuMode === 'overlay',
    'layout-overlay-active': overlayMenuActive,
    'layout-slim': menuMode === 'slim',
    'layout-horizontal': menuMode === 'horizontal',
    'layout-active': menuActive,
    'layout-mobile-active': staticMenuMobileActive,
    'layout-sidebar': menuMode === 'sidebar',
    'layout-sidebar-static': menuMode === 'sidebar' && sidebarStatic,
    'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static',
    'p-ripple-disabled': !ripple,
  })
  const toast = useRef(null)
  const showToaster = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail })
  }

  return (
    <div className={layoutClassName} onClick={onDocumentClick}>
      <div className="layout-main">
        <AppTopbar
          items={menu}
          menuMode={menuMode}
          colorScheme={props.colorScheme}
          menuActive={menuActive}
          topbarMenuActive={topbarMenuActive}
          activeInlineProfile={activeInlineProfile}
          onTopbarItemClick={onTopbarItemClick}
          onMenuButtonClick={onMenuButtonClick}
          onSidebarMouseOver={onSidebarMouseOver}
          onSidebarMouseLeave={onSidebarMouseLeave}
          onToggleMenu={onToggleMenu}
          onChangeActiveInlineMenu={onChangeActiveInlineMenu}
          onMenuClick={onMenuClick}
          onMenuItemClick={onMenuItemClick}
          onRootMenuItemClick={onRootMenuItemClick}
          resetActiveIndex={resetActiveIndex}
        />

        <AppMenu
          model={menu}
          onRootMenuItemClick={onRootMenuItemClick}
          onMenuItemClick={onMenuItemClick}
          onToggleMenu={onToggleMenu}
          onMenuClick={onMenuClick}
          menuMode={menuMode}
          colorScheme={props.colorScheme}
          menuActive={menuActive}
          sidebarActive={sidebarActive}
          sidebarStatic={sidebarStatic}
          pinActive={pinActive}
          onSidebarMouseLeave={onSidebarMouseLeave}
          onSidebarMouseOver={onSidebarMouseOver}
          onRightMenuButtonClick={onRightMenuButtonClick}
          activeInlineProfile={activeInlineProfile}
          onChangeActiveInlineMenu={onChangeActiveInlineMenu}
          resetActiveIndex={resetActiveIndex}
        />

        <div className="layout-main-content">
          <Toast ref={toast} />
          <Switch>
            {(!authToken || authToken === null || authToken === undefined) && (
              <Redirect to="/login" />
            )}
            {/* DASHBAORD */}
            <Route exact path="/" component={DashboardPage} />
            <Route path="/targets" component={TargetsPage} />

            {/* ORDER PAGES */}
            <Route path="/orders" exact component={OrdersPage} />
            <Route path="/orders/manual" component={ManualOrdersPage} />
            <Route path="/orders/bin" component={BinOrdersPage} />
            <Route path="/orders/undelivered" component={UndeliveredOrdersPage} />
            <Route path="/orders/approval-requests" component={OrderApprovalRequestsPage} />
            <Route path="/orders/contact-fix" component={OrderContactFixPage} />
            <Route path="/orders/assign" component={AssignOrderPage} />
            <Route path="/orders/my" component={MyOrdersPage} />
            <Route path="/orders/my-team" component={MyTeamOrdersPage} />
            <Route path="/orders/website-products/price" component={ProductPricePage} />
            <Route path="/orders" component={OrdersPage} />
            {/* ISSUE PAGES */}
            <Route path="/issues/shipment-delays" component={DelayedOrdersPage} />
            <Route path="/issues/cac" component={ComplaintAndCasesPage} />
            <Route path="/issues/dac" component={DisputeAndChargebackPage} />
            <Route path="/issues/ticket-management" component={UserTicketManagementPage} />
            {/* WEBSITE PAGES */}
            <Route path="/website/products" component={ProductsPage} />
            <Route path="/website/category/arrange/:slug" component={CategoryArangePage} />
            <Route path="/website/category" component={CategoryPage} />
            <Route path="/website/coupons" component={CouponsPage} />
            <Route path="/website/discounts" component={DiscountsPage} />
            <Route path="/website/logistics" component={LogisticPage} />
            <Route path="/website/offers" component={OffersPage} />
            <Route path="/website/reviews" component={ReviewsPage} />
            <Route path="/website/faqs" component={FaqsPage} />
            <Route path="/website/order-issues-faqs" component={OrderIssueFaqsPage} />
            <Route path="/website/payment-methods" component={PaymentMethodsPage} />
            <Route path="/website/banners" component={BannersPage} />
            <Route path="/website/dashboard" component={WebsiteDashboardPage} />

            {/* CUSTOMER PAGES */}
            <Route path="/customers" exact component={CustomerPage} />
            <Route path="/customers/enquiries" component={CustomerEnquiryPage} />
            <Route path="/customers/blacklist" component={CustomerBlacklistPage} />
            <Route path="/customers/dnd" component={CustomerDND} />
            <Route path="/customers/cart" component={CartPage} />
            <Route path="/customers/location-whitelist" component={LocationWhitelistPage} />
            <Route path="/customers/auto-cart" component={AutoCartPage} />
            {/* USER PAGES */}
            <Route path="/users" exact component={UsersPage} />
            <Route path="/users/affiliate" component={AffiliatePage} />
            <Route path="/users/activities" component={ActivitiesPage} />
            <Route path="/users/ip-access" component={IpAccessPage} />
            <Route path="/users/teams" component={TeamsPage} />
            {/* OTHERS PAGES */}
            <Route path="/empty" component={EmptyPage} />

            <Route path="*">
              <Redirect to="/notfound" />
            </Route>
          </Switch>
        </div>
      </div>

      <AppProfile
        showToaster={showToaster}
        rightMenuActive={rightMenuActive}
        onRightMenuClick={onRightMenuClick}
        onRightMenuActiveChange={onRightMenuActiveChange}
        colorScheme={props.colorScheme}
        onColorSchemeChange={props.onColorSchemeChange}
        onRightMenuButtonClick={onRightMenuButtonClick}
      />
    </div>
  )
}

export default App
