import { Button } from 'primereact/button'
import React, { useState, useContext, useRef } from 'react'
import {
  categoryNames,
  getShippingCompanyTags,
  getProductNames,
  getProductStrengths,
  getProductQuantities,
  getUsers,
  getTeams,
  getAllWebsites,
  getJson,
  getPaymentMethods,
} from './api'
import { Toast } from 'primereact/toast'
const contentMarkerClassname = 'p-button-rounded p-button-secondary'
const AppContext = React.createContext()

const AppContextProvider = ({ children }) => {
  const toast = useRef(null)
  const [colorScheme, setColorScheme] = useState(
    localStorage.getItem('colorScheme') ? localStorage.getItem('colorScheme') : 'light'
  )
  const [user, setUser] = useState({
    username: '',
    role: '',
    email: '',
    id: null,
    teams: [],
    members: [],
  })

  const productTypes = [
    'pill',
    'bottle',
    'tube',
    'pouch',
    'box',
    'injection',
    'pack',
    'capsule',
    'tablet',
    'inhaler',
    'spray',
    'sachet',
    'ampoule',
    'strip',
    'vial',
    'blister',
    'unit',
    'piece',
    'kit',
  ]
  const [paymentMethods, setPaymentMethods] = useState([])
  const [productCategories, setProductCategories] = useState([])
  const [productSubCategories, setProductSubCategories] = useState([])
  const [productCategoriesWithIds, setProductCategoriesWithIds] = useState([])
  const [productCategoriesGroups, setProductCategoriesGroups] = useState([])
  const [productNames, setProductNames] = useState([])
  const [activeProductNames, setActiveProductNames] = useState([])
  const [productStrengths, setProductStrengths] = useState([])
  const [productQuantities, setProductQuantities] = useState([])
  const [agents, setAgents] = useState([])
  const [websites, setWebsites] = useState([])
  const [config, setConfig] = useState({})
  const [dashWebsites, setDashWebsites] = useState([])
  const [callHippoDialerVisible, setCallHippoDialerVisible] = useState(false)
  const [callHippoDialerUrl, setCallHippoDialerUrl] = useState('https://dialer.callhippo.com/dial')
  const [teams, setTeams] = useState([])

  /* Teams */
  const fetchTeams = async () => {
    const res = await getTeams()
    if (res?.status === 200) {
      let teamName = []
      res.data.map((team) => {
        teamName.push({
          teamID: team.id,
          teamLeadId: team.teamLeadId,
          teamLeadName: team.teamLead,
          teamManagerId: team.teamManagerId,
          teamManagerName: team.teamManager,
          teamName: team.teamName,
          teamMembers: team.members,
        })
      })
      setTeams(teamName)
    }
  }
  /* Shipping TAGS */
  const [shippingTags, setShippingTags] = useState([])
  const fetchTags = async () => {
    const res = await getShippingCompanyTags()
    if (res?.status === 200) {
      setShippingTags(res.data)
    }
  }
  /* Category Fetch */
  const fetchCategories = async () => {
    const res = await categoryNames()
    if (res && res.status === 200) {
      setProductCategoriesWithIds(res.data)
      let _name = [],
        _groupMain = [],
        _groupSub = []
      res.data.forEach((item) => {
        _name.push(item.name)
        if (item.parentCatId) {
          _groupSub.push(item)
        } else {
          _groupMain.push(item)
        }
      })
      setProductCategories(_name)
      setProductSubCategories(_groupSub.map((subCat) => subCat.name))
      setProductCategoriesGroups([
        {
          label: 'Main Category',
          items: _groupMain,
        },
        {
          label: 'Sub Category',
          items: _groupSub,
        },
      ])
    }
  }
  /* Product name fetch */
  const fetchProductNames = async () => {
    const res = await getProductNames()
    if (res && res.status === 200) {
      setProductNames(res.data)
      setActiveProductNames(() => res.data.filter((p) => p.productStatus === 'active'))
    }
  }

  /* Product strength fetch */
  const fetchProductStrengths = async () => {
    const res = await getProductStrengths()
    if (res && res.status === 200) {
      setProductStrengths(res.data)
    }
  }
  /* Product qty and price fetch */
  const fetchProductQuantities = async () => {
    const res = await getProductQuantities()
    if (res && res.status === 200) {
      setProductQuantities(res.data)
    }
  }
  /* Agent name fetch */
  const fetchAgents = async () => {
    const res = await getUsers()
    if (res && res.status === 200) {
      setAgents(res.data)
    }
  }

  /* fetch all websites */
  const fetchAllWebsites = async () => {
    const res = await getJson('allWebsiteArrayObj')
    if (res && res.status === 200) {
      setWebsites(res?.data)
    }
  }
  /* fetch dash website */
  const fetchDashWebsites = async () => {
    const res = await getJson('dashWebsiteArray')
    if (res && res.status === 200) {
      setDashWebsites(res?.data)
    }
  }

  const fetchConfig = async () => {
    const res = await getJson('config')
    if (res && res.status === 200) {
      setConfig(res?.data)
    }
  }

  /* fetch payment methods */
  const fetchPaymentMethods = async () => {
    const res = await getPaymentMethods()
    if (res && res.status === 200) {
      setPaymentMethods(res.data.sort((a, b) => (a.status < b.status ? 1 : -1)))
    }
  }
  /* Stats */
  const userOptions = [
    { name: 'Agent', value: 'agent' },
    { name: 'Team Lead', value: 'teamlead' },
    { name: 'Manager', value: 'manager' },
    { name: 'Operations', value: 'operations' },
    { name: 'Admin', value: 'admin' },
  ]

  const orderStatus = [
    { name: 'Draft', value: 'Draft' },
    { name: 'Pending', value: 'Pending' },
    { name: 'Processed', value: 'Processed' },
    { name: 'Shipped', value: 'Shipped' },
    { name: 'Tracking', value: 'Tracking' },
    { name: 'Tracking-Live', value: 'Tracking-Live' },
    { name: 'Delivered', value: 'Delivered' },
    { name: 'Undelivered', value: 'Undelivered' },
    { name: 'Cancelled', value: 'Cancelled' },
    { name: 'Partial', value: 'Partial' },
    { name: 'Bin', value: 'Bin' },
    { name: 'Reshipment-Initiated', value: 'Reshipment-Initiated' },
    { name: 'Reshipment-Completed', value: 'Reshipment-Completed' },
    { name: 'Refund-Initiated', value: 'Refund-Initiated' },
    { name: 'Refund-Completed', value: 'Refund-Completed' },
    { name: 'Ineligible', value: 'Ineligible' },
    { name: 'Ineligi-Cancel', value: 'Ineligi-Cancel' },
  ]

  const orderIssueOptions = [
    'Common Issues Faced While Ordering',
    'Common Issues Faced During Payments',
    'Common Issues Faced In Shipping',
    'Common Issues Faced In Order Tracking',
    'Common Issues Faced In Order Delivery',
    'Others',
  ]

  const leadSource = [
    { name: 'ORGANIC', value: 'ORGANIC' },
    { name: 'INDMRT', value: 'INDMRT' },
    { name: 'REORDER', value: 'REORDER' },
    { name: 'POS', value: 'POS' },
    { name: 'PAS', value: 'PAS' },
  ]

  const allStatusGroups = [
    {
      name: 'Paid',
      items: [
        { name: 'Processed', value: 'Processed' },
        { name: 'Shipped', value: 'Shipped' },
        { name: 'Tracking', value: 'Tracking' },
        { name: 'Tracking-Live', value: 'Tracking-Live' },
        { name: 'Delivered', value: 'Delivered' },
        { name: 'Undelivered', value: 'Undelivered' },
        { name: 'Partial', value: 'Partial' },
        { name: 'Reshipment-Initiated', value: 'Reshipment-Initiated' },
        { name: 'Reshipment-Completed', value: 'Reshipment-Completed' },
        { name: 'Refund-Initiated', value: 'Refund-Initiated' },
        { name: 'Refund-Completed', value: 'Refund-Completed' },
      ],
    },
    {
      name: 'Unpaid',
      items: [
        { name: 'Pending', value: 'Pending' },
        { name: 'Draft', value: 'Draft' },
        { name: 'Cancelled', value: 'Cancelled' },
      ],
    },
    {
      name: 'Unqualified',
      items: [
        { name: 'Ineligible', value: 'Ineligible' },
        { name: 'Ineligi-Cancel', value: 'Ineligi-Cancel' },
      ],
    },
  ]

  const allStatusGroupsOnly = [
    { name: 'All', value: 'All' },
    { name: 'Paid', value: 'Paid' },
    { name: 'Unpaid', value: 'Unpaid' },
    { name: 'Unqualified', value: 'Unqualified' },
  ]

  return (
    <AppContext.Provider
      value={{
        toast,
        user,
        setUser,
        colorScheme,
        setColorScheme,
        getActionBtn,
        shippingTags,
        productTypes,
        productCategories,
        productSubCategories,
        productCategoriesWithIds,
        productNames,
        activeProductNames,
        productStrengths,
        productQuantities,
        agents,
        teams,
        fetchTags,
        fetchTeams,
        fetchCategories,
        setShippingTags,
        fetchProductNames,
        fetchProductStrengths,
        fetchProductQuantities,
        fetchAgents,
        fetchAllWebsites,
        fetchDashWebsites,
        fetchConfig,
        websites,
        dashWebsites,
        orderStatus,
        callHippoDialerVisible,
        setCallHippoDialerVisible,
        callHippoDialerUrl,
        setCallHippoDialerUrl,
        orderIssueOptions,
        leadSource,
        allStatusGroups,
        allStatusGroupsOnly,
        productCategoriesGroups,
        userOptions,
        paymentMethods,
        fetchPaymentMethods,
        config,
        
      }}
    >
      <Toast ref={toast} />
      {children}
    </AppContext.Provider>
  )
}
const getActionBtn = (type) => {
  let fragment

  switch (type) {
    case 'create': {
      fragment = <Button disabled icon="pi pi-plus" className={contentMarkerClassname} />
      break
    }
    case 'read': {
      fragment = <Button disabled icon="pi pi-eye" className={contentMarkerClassname} />
      break
    }
    case 'update': {
      fragment = <Button disabled icon="pi pi-pencil" className={contentMarkerClassname} />
      break
    }
    case 'delete': {
      fragment = <Button disabled icon="pi pi-trash" className={contentMarkerClassname} />
      break
    }

    case 'pending': {
      fragment = <Button disabled icon="pi pi-clock" className={contentMarkerClassname} />
      break
    }
    case 'processed': {
      fragment = <Button disabled icon="pi pi-money-bill" className={contentMarkerClassname} />
      break
    }
    case 'shipped': {
      fragment = <Button disabled icon="pi pi-table" className={contentMarkerClassname} />
      break
    }
    case 'tracking': {
      fragment = <Button disabled icon="pi pi-map-marker" className={contentMarkerClassname} />
      break
    }
    case 'tracking-live': {
      fragment = <Button disabled icon="pi pi-map-marker" className={contentMarkerClassname} />
      break
    }

    case 'partial': {
      fragment = <Button disabled icon="pi pi-sliders-h" className={contentMarkerClassname} />
      break
    }
    case 'delivered': {
      fragment = <Button disabled icon="pi pi-home" className={contentMarkerClassname} />
      break
    }
    case 'general': {
      fragment = <Button disabled icon="pi pi-circle-fill" className={contentMarkerClassname} />
      break
    }

    default: {
      fragment = <Button disabled icon="pi pi-trash" className={contentMarkerClassname} />
    }
  }
  return fragment
}
// make sure use
export const useGlobalContext = () => {
  return useContext(AppContext)
}

export { AppContext, AppContextProvider }
