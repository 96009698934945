import React, { useEffect, useRef, useState } from 'react'
import { useGlobalContext } from '../../context'
import { Toast } from 'primereact/toast'
import { TabPanel, TabView } from 'primereact/tabview'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'
import ExportButton from '../../components/mini/ExportButton'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'
import { InputText } from 'primereact/inputtext'
import {
  getAvailableCoupons,
  getCoupons,
  getProductQuantities,
  getProductStrengths,
} from '../../api'
import { Message } from 'primereact/message'
import { handleObjChange } from '../../functions/setter'
import { Chips } from 'primereact/chips'
import { RadioButton } from 'primereact/radiobutton'
import classNames from 'classnames'

function AutoCartPage() {
  const { toast, config, activeProductNames, user } = useGlobalContext()

  const emptyProduct = {
    productCode: '',
    strengths: [],
    strengthCode: '',
    quantities: [],
    quantityCode: '',
    discountedPerPillPrice: '',
    quantity: '',
  }
  const emptyRecord = {
    products: [emptyProduct],
    couponCode: '',
    userRef: 0,
  }

  const emptyErr = {
    state: false,
    errors: [],
  }

  const [autoCartRecord, setAutoCartRecord] = useState(emptyRecord)
  const [linkRecord, setLinkRecord] = useState({
    linkEnable: false,
    link: '',
  })
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const [coupons, setCoupons] = useState([])
  const [loading, setLoading] = useState({
    generateLinkLoading: false,
    getAvailableCouponsLoading: false,
  })

  // Fetch coupon records
  const fetchAvailableCoupons = async () => {
    const errs = validateFn('generateCoupon')
    if (errs.length) {
      setNewRecordError({
        state: true,
        errors: errs,
      })
      return
    }
    setLoading({
      ...loading,
      getAvailableCouponsLoading: true,
    })
    const res = await getAvailableCoupons(
      autoCartRecord.products.map((record) => ({
        productCode: record.productCode,
        strengthCode: record.strengthCode,
        quantityCode: record.quantityCode,
        discountedPerPillPrice: record.discountedPerPillPrice,
        quantity: record.quantity,
        productCategory: record.productCategory,
        productMainCategory: record.productMainCategory,
      }))
    )
    if (res) {
      if (res.status === 200) {
        setCoupons(res.data.coupons)
        toast.current.show({
          severity: 'success',
          summary: 'Coupons fetched',
          detail: 'Coupons fetched successfully',
        })
      }
    }
    setLoading({
      ...loading,
      getAvailableCouponsLoading: false,
    })
  }
  
  const validateFn = (type) => {
    const errs = []
    autoCartRecord.products.forEach((record, idx) => {
      if (!record.productCode) {
        errs.push(`Product is required in row ${idx + 1}`)
      }
      if (!record.strengthCode) {
        errs.push(`Strength is required in row ${idx + 1}`)
      }
      if (!record.quantityCode) {
        errs.push(`Quantity is required in row ${idx + 1}`)
      }
    })
    if (type === 'generateLink' && !autoCartRecord.userRef) {
      errs.push(`User is required`)
    }
    return errs
  }

  const generateLink = () => {
    const errs = validateFn('generateLink')
    if (errs.length) {
      setNewRecordError({
        state: true,
        errors: errs,
      })
      return
    }
    setLoading({
      ...loading,
      generateLinkLoading: true,
    })
    // generate link
    // format: cart?autoProd=PCODE-SCODE-QCODE__PCODE-SCODE-QCODE&autoCoupon=CODE
    let link = `${config?.websiteUrl}/cart/checkout?`
    link += `autoProd=`
    autoCartRecord.products.forEach(
      (record, idx) =>
        (link += `${idx ? '__' : ''}${record.productCode}-${record.strengthCode}-${
          record.quantityCode
        }`)
    )
    if (autoCartRecord.couponCode) {
      link += `&autoCoupon=${autoCartRecord.couponCode}`
    }
    link += `&u_ref=${autoCartRecord.userRef}`
    setLoading({
      ...loading,
      generateLinkLoading: false,
    })
    setLinkRecord({
      linkEnable: true,
      link,
    })
  }

  const productAlreadExists = (productCode, strengthCode, quantityCode) => {
    return autoCartRecord.products.some(
      (record) =>
        record.productCode === productCode &&
        record.strengthCode === strengthCode &&
        record.quantityCode === quantityCode
    )
  }

  const handleProductSelect = async (e, record, idx) => {
    let temp = [...autoCartRecord.products]
    const selectedProduct = activeProductNames.find((item) => item.productCode === e.value)
    if (selectedProduct.isStockOut)
      return toast.current.show({
        severity: 'warn',
        summary: 'Stock out',
        detail: 'Please select another one',
      })
    temp[idx].productCode = e.value
    temp[idx].strengths = await getProductStrengths({
      productCode: e.value,
    })
      .then((res) => res.data)
      .catch((err) => console.log(err))
    temp[idx].productCategory = selectedProduct?.productCategory
    temp[idx].productMainCategory = selectedProduct?.productMainCategory
    handleObjChange(setAutoCartRecord, 'products', temp)
  }

  const handleStrengthSelect = async (e, record, idx) => {
    let temp = [...autoCartRecord.products]
    const selectedStrength = record.strengths.find((item) => item.strengthCode === e.value)
    if (selectedStrength.isStockOut)
      return toast.current.show({
        severity: 'warn',
        summary: 'Stock Oout',
        detail: 'Please select another one',
      })
    temp[idx].strengthCode = e.value
    temp[idx].quantities = await getProductQuantities({
      productCode: record.productCode,
      strengthCode: e.value,
    })
      .then((res) =>
        res.data.map((item) => {
          return {
            ...item,
            quantity: String(item.quantity),
          }
        })
      )
      .catch((err) => console.log(err))
    handleObjChange(setAutoCartRecord, 'products', temp)
  }

  const handleQuantitySelect = (e, record, idx) => {
    if (productAlreadExists(record.productCode, record.strengthCode, e.value)) {
      toast.current.show({
        severity: 'warn',
        summary: 'Quantity already exists',
        detail: 'Please select another one',
      })
      return
    }
    const selectedQuantity = record.quantities.find((item) => item.quantityCode === e.value)
    if (selectedQuantity.isStockOut)
      return toast.current.show({
        severity: 'warn',
        summary: 'Stock out',
        detail: 'Please select another one',
      })
    let temp = [...autoCartRecord.products]
    temp[idx].quantityCode = e.value
    temp[idx].discountedPerPillPrice = selectedQuantity?.discountedPerPillPrice
    temp[idx].quantity = selectedQuantity?.quantity
    handleObjChange(setAutoCartRecord, 'products', temp)
  }

  const addRemoveItem = (type, idx) => {
    let temp = [...autoCartRecord.products]
    if (type === 'add') {
      temp.push(emptyProduct)
    }
    if (type === 'remove') {
      temp = temp.filter((item, i) => i !== idx)
    }
    handleObjChange(setAutoCartRecord, 'products', temp)
  }

  return (
    <>
      <Toast ref={toast} />
      <div className="">
        {/* Open complaints section start*/}
        <Toolbar
          className="card p-toolbar p-mb-3 p-flex-wrap gap-1"
          left={
            <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
              <h4 className="p-m-0">Auto Cart</h4>
            </div>
          }
        ></Toolbar>
        <div className="card">
          <div className="p-grid">
            {autoCartRecord.products.map((record, idx) => (
              <React.Fragment key={idx}>
                <div className="p-field p-fluid p-col-12 p-md-3">
                  <label className="p-d-block">Product</label>
                  <Dropdown
                    placeholder="Select Product"
                    options={activeProductNames ?? []}
                    optionLabel="productName"
                    optionValue="productCode"
                    value={record?.productCode}
                    disabled={linkRecord.linkEnable}
                    filter
                    filterBy="productName"
                    onChange={async (e) => handleProductSelect(e, record, idx)}
                    itemTemplate={(option) => (
                      <span
                        className={classNames({
                          'opacity-50': option.isStockOut,
                        })}
                      >
                        {option.productName}
                      </span>
                    )}
                  />
                </div>
                <div className="p-field p-fluid p-col-12 p-md-3">
                  <label htmlFor="" className="p-d-block">
                    Strength
                  </label>
                  <Dropdown
                    placeholder="Select Strength"
                    options={record?.strengths ?? []}
                    optionLabel="strengthName"
                    optionValue="strengthCode"
                    filter
                    filterBy="strengthName"
                    disabled={!record?.productCode || linkRecord.linkEnable}
                    value={record?.strengthCode}
                    onChange={async (e) => handleStrengthSelect(e, record, idx)}
                    itemTemplate={(option) => (
                      <span
                        className={classNames({
                          'opacity-50': option.isStockOut,
                        })}
                      >
                        {option.strengthName}
                      </span>
                    )}
                  />
                </div>
                <div className="p-field p-fluid p-col-12 p-md-3">
                  <label className="p-d-block">Quantity</label>
                  <Dropdown
                    placeholder="Select Quantity"
                    options={record?.quantities ?? []}
                    optionLabel="quantity"
                    optionValue="quantityCode"
                    disabled={!record?.strengthCode || linkRecord.linkEnable}
                    value={record?.quantityCode}
                    onChange={(e) => handleQuantitySelect(e, record, idx)}
                    itemTemplate={(option) => (
                      <span
                        className={classNames({
                          'opacity-50': option.isStockOut,
                        })}
                      >
                        {option.quantity}
                      </span>
                    )}
                  />
                </div>
                <div className="p-field p-fluid p-col-12 p-md-3 p-d-flex p-jc-end p-ai-end gap-1">
                  <div className="w-full">
                    <Button
                      label="Remove"
                      className="p-button-danger"
                      icon="pi pi-trash"
                      disabled={autoCartRecord.products.length === 1 || linkRecord.linkEnable}
                      onClick={() => addRemoveItem('remove', idx)}
                    />
                  </div>
                  <div className="w-full">
                    <Button
                      label="Add"
                      icon="pi pi-plus"
                      disabled={linkRecord.linkEnable}
                      onClick={() => addRemoveItem('add', idx)}
                    />
                  </div>
                </div>
              </React.Fragment>
            ))}
            <div className="p-field p-fluid p-col-12 p-md-3">
              <label className="p-d-block">User Ref.</label>
              <Dropdown
                placeholder="Select User"
                options={
                  user && user.members.filter((mem) => ['agent', 'teamlead'].includes(mem.role))
                }
                optionLabel="username"
                optionValue="id"
                disabled={linkRecord.linkEnable}
                value={autoCartRecord.userRef}
                onChange={(e) => handleObjChange(setAutoCartRecord, 'userRef', e.value)}
              />
            </div>

            <div className="p-field p-fluid p-col-9">
              <label className="p-d-block">Coupons</label>
              {coupons?.length <= 0 ? (
                <div className=" p-mt-3">No coupons available</div>
              ) : (
                <div className="p-d-flex gap-1 p-flex-wrap p-mt-3">
                  {coupons.map((coupon, idx) => {
                    return (
                      <div className="flex align-items-center" key={idx}>
                        <RadioButton
                          inputId={coupon.id}
                          name={coupon.description}
                          value={coupon.code}
                          disabled={linkRecord.linkEnable}
                          onChange={(e) =>
                            handleObjChange(setAutoCartRecord, 'couponCode', e.value)
                          }
                          checked={autoCartRecord.couponCode === coupon.code}
                        />
                        <label htmlFor={coupon.id} className="p-ml-2">
                          {coupon.code}
                        </label>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>

            {newRecordError.state && (
              <>
                {newRecordError.errors.map((err, idx) => {
                  return (
                    <div key={idx} className="p-fluid p-filed p-col-6">
                      <Message text={err} severity="warn" sticky={true} />
                    </div>
                  )
                })}
                <div className="p-fluid p-field p-col-12">
                  <Button
                    type="button"
                    onClick={() => {
                      setNewRecordError(emptyErr)
                    }}
                    icon="pi pi-times"
                    label="Clear Warnings"
                    className="p-button-secondary"
                  ></Button>
                </div>
              </>
            )}
            <div className="p-field p-fluid p-col-3">
              <Button
                label="Get Coupons"
                className="p-button-info"
                disabled={linkRecord.linkEnable}
                loading={loading.getAvailableCouponsLoading}
                onClick={() => fetchAvailableCoupons()}
              />
            </div>
            <div className="p-field p-fluid p-col-6">
              <Button
                label={'Generate Link'}
                disabled={linkRecord.linkEnable}
                icon="pi pi-link"
                loading={loading.generateLinkLoading}
                onClick={() => generateLink()}
              ></Button>
            </div>
            <div className="p-field p-fluid p-col-3">
              <Button
                label="Discard"
                className="p-button-danger"
                icon="pi pi-trash"
                disabled={!linkRecord.linkEnable}
                onClick={() =>
                  setLinkRecord({
                    linkEnable: false,
                    link: '',
                  })
                }
              ></Button>
            </div>
          </div>
        </div>
        {linkRecord.linkEnable && (
          <div className="card p-d-flex gap-1">
            <InputText readOnly className="w-full" value={linkRecord.link} />

            <Button
              label="Copy"
              className="p-px-5"
              icon="pi pi-copy"
              onClick={() => {
                navigator.clipboard?.writeText(linkRecord.link)
                toast.current.show({
                  severity: 'success',
                  summary: 'Copied',
                  detail: 'Link copied',
                })
              }}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default AutoCartPage
