import { nanoid } from 'nanoid'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import React, { useState } from 'react'
import { useGlobalContext } from '../../context'
import { handleObjChange } from '../../functions/setter'
import { Message } from 'primereact/message'
import classNames from 'classnames'
import { fetchQuantity, fetchStrength } from '../../functions/utilityFunctions'
export default function AddNewProductInOrder({
  visible,
  setVisible,
  newProduct,
  setNewProduct,
  products,
  setProducts,
}) {
  /**
   * @param {boolean} visible - The boolean to set the add new product dialog visible
   * @param {function} setVisible - The function to set the add new product dialog visible
  //  * @param {object} newProduct - The object to store the new product data
  //  * @param {function} setNewProduct - The function to set the new product data
   * @param {array} products - The array of products in the order
   * @param {function} setProducts - The function to set the array of products in the order
   * @description - The dialog to add new product in the order
   * **/

  const emptyErr = {
    state: false,
    errors: [],
  }
  const { activeProductNames, shippingTags } = useGlobalContext()
  const [newNewProductError, setNewProductError] = useState(emptyErr)
  const [strengths, setStrengths] = useState([])
  const [quantities, setQuantities] = useState([])

  const validateNewProduct = () => {
    const errors = []
    if (!newProduct?.productName) errors.push('Product Name is required')
    if (!newProduct?.strengthName) errors.push('Strength Name is required')
    if (!newProduct?.quantity) errors.push('Quantity is required')
    if (!newProduct?.shippingCompany) errors.push('Shipping Company is required')
    if (!newProduct?.totalQuantity && newProduct?.totalQuantity <= 0)
      errors.push('Total quantity must be 1 or greater')
    if (!newProduct?.quantityPrice && newProduct?.quantityPrice <= 0)
      errors.push('Please enter a valid price')
    return errors
  }

  const onProductAddToLocalOrder = () => {
    const errors = validateNewProduct()
    if (errors.length > 0) {
      setNewProductError({
        state: true,
        errors: errors,
      })
      return
    }
    const id = products[products?.length - 1]?.id + 1
    const _newProduct = {
      id,
      productName: newProduct.productName,
      productCode: newProduct.productCode,
      productCategory: newProduct.productCategory,
      shippingCompany: newProduct.shippingCompany,
      strength: newProduct.strengthName,
      strengthCode: newProduct.strengthCode,
      _strength: newProduct.strengthName,
      quantity: newProduct.quantity,
      quantityCode: newProduct.quantityCode,
      _quantity: newProduct.quantity,
      quantityPrice: newProduct.quantityPrice,
      quantityCost: newProduct.quantityCost,
      status: 'Pending',
      totalQuantity: newProduct.totalQuantity || 1,
      nanoid: nanoid(),
      unlinked: true,
      totalPrice: parseFloat(newProduct.totalPrice).toFixed(2),
      total: parseFloat(newProduct.total).toFixed(2),
      isShipmentDelayEnabled: true,
    }
    setProducts([...products, _newProduct])
    onHideDialog()
  }

  const onHideDialog = async () => {
    setVisible(false)
    resetProduct()
  }

  const productDialogFooter = (
    <>
      <Button label="Submit" icon="pi pi-save" onClick={() => onProductAddToLocalOrder()} />
      <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={onHideDialog} />
    </>
  )

  const resetProduct = () => {
    setNewProduct({})
    setNewProductError(emptyErr)
    setStrengths([])
    setQuantities([])
  }

  return (
    <Dialog
      visible={visible}
      header="Select Product"
      modal
      className="p-fluid"
      style={{ width: '1000px' }}
      footer={productDialogFooter}
      onHide={onHideDialog}
    >
      <div className="p-fluid p-formgrid p-grid">
        <div className="p-fluid p-field p-col-12 p-lg-3 p-mb-4">
          <p className="p-mb-2" htmlFor="productName">
            Product Name
          </p>
          <Dropdown
            editable
            value={newProduct?.productName}
            options={activeProductNames}
            optionLabel="productName"
            name="product"
            onChange={(e) => {
              resetProduct()
              if (e.value.isStockOut) return
              handleObjChange(setNewProduct, 'productName', e.value.productName || e.value)
              handleObjChange(setNewProduct, 'productCode', e.value.productCode || '')
              handleObjChange(setNewProduct, 'productCategory', e.value.productCategory || '')
              handleObjChange(setNewProduct, 'shippingCompany', e.value.shippingCompany || '')
              if (e.value.productName) fetchStrength(e.value.productCode, setStrengths)
            }}
            itemTemplate={(option) => (
              <span
                className={classNames({
                  'opacity-50': option.isStockOut,
                })}
              >
                {option.productName}
              </span>
            )}
            filter
            filterBy="productName,altNames,ingredients,productCategory,shippingCompany"
            placeholder="Select Product"
            emptyMessage="Loading Products"
          />
        </div>
        <div className="p-fluid p-field p-col-12 p-lg-3 p-mb-4">
          <p className="p-mb-2" htmlFor="state">
            Strength
          </p>
          <Dropdown
            editable
            value={newProduct?.strengthName}
            options={strengths}
            optionLabel="strengthName"
            name="strength"
            onChange={(e) => {
              if (e.value.isStockOut) return
              handleObjChange(setNewProduct, 'strengthName', e.value.strengthName || e.value)
              handleObjChange(setNewProduct, '_strength', e.value.strengthName || e.value)
              handleObjChange(setNewProduct, 'strengthCode', e.value.strengthCode || '')
              if (e.value.strengthName) fetchQuantity(e.value.strengthCode, setQuantities)
            }}
            itemTemplate={(option) => (
              <span
                className={classNames({
                  'opacity-50': option.isStockOut,
                })}
              >
                {option.strengthName}
              </span>
            )}
            filter
            filterBy="strengthName"
            placeholder="Select Strength"
            emptyMessage="No Strength found"
          />
        </div>
        <div className="p-fluid p-field p-col-12 p-lg-3 p-mb-4">
          <p className="p-mb-2" htmlFor="state">
            No. Pills
          </p>
          <Dropdown
            editable
            value={newProduct?.quantity}
            options={quantities}
            onChange={(e) => {
              if (e.value.isStockOut) return
              handleObjChange(setNewProduct, 'quantity', e.value.quantity || e.value)
              handleObjChange(setNewProduct, '_quantity', e.value.quantity || e.value)
              handleObjChange(setNewProduct, 'quantityCode', e.value.quantityCode || '')
              handleObjChange(setNewProduct, 'quantityCost', e.value.cost || 0)
              handleObjChange(setNewProduct, 'quantityPrice', e.value.price || 0)
              handleObjChange(
                setNewProduct,
                'totalPrice',
                e.value.price * e.value.quantity * newProduct?.totalQuantity || 0
              )
              handleObjChange(
                setNewProduct,
                'total',
                e.value.price * e.value.quantity * (newProduct?.totalQuantity || 1) || 0
              )
            }}
            itemTemplate={(option) => (
              <span
                className={classNames({
                  'opacity-50': option.isStockOut,
                })}
              >
                {option.quantity}
              </span>
            )}
            optionLabel="quantity"
            name="quantity"
            filter
            filterBy="quantity"
            placeholder="Select Quantity"
            emptyMessage="No Quantity found"
          />
        </div>
        <div className="p-fluid p-field p-col-12 p-lg-3 p-mb-4">
          <p className="p-mb-2" htmlFor="state">
            Pill Price
          </p>
          <InputNumber
            step={0.25}
            id="price"
            placeholder="$0.00"
            optionLabel="quantityPrice"
            name="quantityPrice"
            prefix="$"
            disabled={!newProduct?.productName}
            mode="decimal"
            locale="en-US"
            maxFractionDigits={2}
            minFractionDigits={2}
            value={newProduct?.quantityPrice}
            onChange={(e) => {
              handleObjChange(setNewProduct, 'quantityPrice', e.value)
              handleObjChange(setNewProduct, 'totalPrice', e.value * newProduct?.quantity || 0)
              handleObjChange(
                setNewProduct,
                'total',
                Number(e.value) * newProduct?.quantity * (newProduct?.totalQuantity || 1) || 0
              )
            }}
            style={{ width: '100%' }}
          />
        </div>

        <div className="p-fluid p-field p-col-12 p-lg-3 p-mb-4">
          <p className="p-mb-2" htmlFor="state">
            Quantity
          </p>
          <InputNumber
            id="price"
            name="totalQuantity"
            disabled={
              !newProduct?.quantity ||
              !newProduct?.strengthName ||
              !newProduct?.productName ||
              !newProduct?.quantityPrice
            }
            value={newProduct?.totalQuantity || 1}
            onChange={(e) => {
              handleObjChange(setNewProduct, 'totalQuantity', e.value)
              handleObjChange(
                setNewProduct,
                'total',
                Number(newProduct?.quantityPrice) * newProduct?.quantity * (e.value || 1)
              )
            }}
            style={{ width: '100%' }}
            placeholder="Total Quantity"
          />
        </div>
        <div className="p-fluid p-field p-col-12 p-lg-3 p-mb-4">
          <p className="p-mb-2" htmlFor="shipper">
            Shipper
          </p>
          <Dropdown
            id="shipper"
            value={newProduct.shippingCompany}
            onChange={(e) => handleObjChange(setNewProduct, 'shippingCompany', e.value)}
            disabled={newProduct?.productCode}
            options={shippingTags}
            placeholder="Shipper"
          />
        </div>
        <div className="p-fluid p-field p-col-12 p-lg-3 p-mb-4">
          <p className="p-mb-2" htmlFor="state">
            Total Price
          </p>
          <InputNumber
            id="price"
            optionLabel="total"
            mode="decimal"
            locale="en-US"
            maxFractionDigits={2}
            minFractionDigits={2}
            name="total"
            disabled
            prefix="$"
            value={newProduct?.total || 0}
            style={{ width: '100%' }}
          />
        </div>
        <div className="p-fluid p-field p-col-12 p-lg-3 p-mb-4">
          <p className="p-mb-2" htmlFor="state">
            Pill Cost
          </p>
          <InputNumber
            step={0.25}
            id="cost"
            optionLabel="quantityCost"
            name="quantityCost"
            prefix="$"
            disabled={
              !newProduct?.quantity?.quantity ||
              !newProduct?.strength?.strengthName ||
              !newProduct?.product?.productName
            }
            mode="decimal"
            locale="en-US"
            maxFractionDigits={2}
            minFractionDigits={2}
            value={newProduct?.quantityCost}
            onValueChange={(e) => handleObjChange(setNewProduct, 'quantityCost', e.value)}
            style={{ width: '100%' }}
          />
        </div>
      </div>
      {newNewProductError.state && (
        <>
          {newNewProductError.errors.map((err, idx) => {
            return (
              <div key={idx} className="p-fluid p-filed p-col-12">
                <Message text={err} severity="warn" sticky={true} />
              </div>
            )
          })}
          <div className="p-fluid p-field p-col-12">
            <Button
              type="button"
              onClick={() => {
                setNewProductError(emptyErr)
              }}
              icon="pi pi-times"
              label="Clear Warnings"
              className="p-button-secondary"
            ></Button>
          </div>
        </>
      )}
    </Dialog>
  )
}
