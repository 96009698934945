import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Message } from 'primereact/message'
import { TabPanel, TabView } from 'primereact/tabview'
import { Toast } from 'primereact/toast'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'

import React, { useCallback, useEffect, useRef, useState } from 'react'
import validator from 'validator'
import {
  deleteBlacklistCustomer,
  getBlacklistCustomer,
  getBlacklistCustomers,
  getCustomers,
  patchBlacklistCustomerStatus,
  postBlacklistCustomer,
  putBlacklistCustomerRemoveRequest,
} from '../../api/customer'
import { FilterMatchMode } from 'primereact/api'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Toolbar } from 'primereact/toolbar'
import { Dropdown } from 'primereact/dropdown'
import { Dialog } from 'primereact/dialog'
import { format } from 'date-fns'
import classNames from 'classnames'
import { useGlobalContext } from '../../context'
import ChangeHistorySidebar from '../../components/mycomponents/ChangeHistorySidebar'
import HideDetails from '../../components/mini/HideDetails'
import ExportButton from '../../components/mini/ExportButton'
import { dtFilenameDate } from '../../functions/myDateFns'
import validatePhoneNumber from '../../functions/validatePhoneNumber'

export default function CustomerPage() {
  const { user, agents } = useGlobalContext()

  const toast = useRef(null)
  const dt = useRef(null)
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const id = parseInt(searchParams.get('id'))
  const emptyFilterObject = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    removeRequest: { value: null, matchMode: FilterMatchMode.EQUALS },
    createdBy: { value: null, matchMode: FilterMatchMode.EQUALS },
  }
  const emptyRecord = {
    firstname: null,
    lastname: null,
    email: null,
    phone: null,
    streetAddress: null,
    addReason: null,
    conditionForRemoval: null,
  }
  const emptyRecordRequest = {
    id: null,
    removeReason: null,
    loading: false,
    visible: false,
  }
  const [filters, setFilters] = useState(emptyFilterObject)
  const [records, setRecords] = useState([])
  const [record, setRecord] = useState(emptyRecord)
  const [recordRequest, setRecordRequest] = useState(emptyRecordRequest)

  const [recordVisible, setRecordVisible] = useState(false)

  const [tableLoading, setTableLoading] = useState(false)
  const [limit, setLimit] = useState(10)

  const onChangeFilter = (value, target) => {
    let _filters = { ...filters }
    _filters[target].value = value
    setFilters(_filters)
  }
  const hideRecord = () => {
    setRecord(emptyRecord)
    setRecordVisible(false)
    history.push({
      pathname: '/customers/blacklist',
    })
  }
  const hideRecordRequest = () => {
    setRecordRequest(emptyRecordRequest)
  }
  const fetchRecord = useCallback(async () => {
    if (!id) return
    setTableLoading(true)
    const res = await getBlacklistCustomer(id)
    if (res) {
      setTableLoading(false)
      if (res.status === 404) {
        hideRecord()
      }
      if (res.status === 200) {
        setRecord((ps) => ({
          ...ps,
          ...res.data.record,
        }))
        setRecordVisible(true)
      }
    }
  }, [id])
  useEffect(() => fetchRecord(), [fetchRecord])

  const fetchRecords = useCallback(async () => {
    setTableLoading(true)
    const res = await getCustomers(limit)
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        setRecords(res.data)
      }
    }
  }, [limit])
  useEffect(() => fetchRecords(), [fetchRecords])

  return (
    <div className="card">
      <Toast ref={toast} />

      <Toolbar
        className="p-toolbar p-flex-wrap gap-1"
        left={
          <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
            <h4 className="p-m-0">Customers</h4>
          </div>
        }
        right={
          <div className="p-d-flex p-flex-wrap gap-1">
            <Button icon="pi pi-refresh" onClick={fetchRecords} />
            <ExportButton datatable_ref={dt} />
          </div>
        }
      ></Toolbar>
      <DataTable
        exportFilename={`Customer_${dtFilenameDate}`}
        ref={dt}
        loading={tableLoading}
        value={records}
        filters={filters}
        showGridlines
        dataKey="id"
        paginator
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
        className="datatable-responsive"
        columnResizeMode="fit"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
        rowHover
        emptyMessage="No record found."
        breakpoint="1200px"
        responsiveLayout="stack"
        header={
          <div className="p-d-flex p-flex-wrap gap-1by2 p-jc-end">
            <div className=" p-d-flex p-ai-center">
              <p className="p-m-0 p-pr-2">Created By</p>
              <Dropdown
                placeholder="User"
                options={agents}
                optionLabel="username"
                optionValue="username"
                filter
                filterBy="username"
                filterPlaceholder="Search..."
                value={filters.createdBy.value}
                onChange={(e) => {
                  onChangeFilter(e.target.value, 'createdBy')
                }}
                showClear
              />
            </div>
            <div className=" p-d-flex p-ai-center">
              <p className="p-m-0 p-pr-2">Request</p>
              <Dropdown
                placeholder="Request"
                options={['Yes', 'No']}
                value={filters.removeRequest.value}
                onChange={(e) => {
                  onChangeFilter(e.target.value, 'removeRequest')
                }}
                showClear
              />
            </div>
            <div className=" p-d-flex p-ai-center">
              <p className="p-m-0 p-pr-2">Limit</p>
              <Dropdown
                placeholder="Limit"
                options={[10, 50, 100, 500, 'All']}
                onChange={(e) => {
                  setLimit(e.value)
                }}
                value={limit}
              />
            </div>

            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                type="search"
                value={filters.global.value}
                onChange={(e) => {
                  onChangeFilter(e.target.value, 'global')
                }}
                placeholder="Search..."
              />
            </span>
          </div>
        }
      >
        <Column style={{ width: '0%' }} header="ID" field="id" sortable />
        <Column style={{ width: '3%' }} header="Name" field="name" />
        <Column
          style={{ width: '1%' }}
          header="Email"
          field="email"
          body={(rd) => <HideDetails value={rd.email} style={{ background: 'none', border: 0 }} />}
        />
        <Column
          style={{ width: '1%' }}
          header="Phone"
          field="phone"
          body={(rd) => <HideDetails value={rd.phone} style={{ background: 'none', border: 0 }} />}
        />
        <Column style={{ width: '10%' }} header="Add Reason" field="addReason" />
        <Column style={{ width: '1%' }} header="Created By" field="createdBy" />
        <Column style={{ width: '1%' }} header="Remove Request" field="removeRequest" />
        <Column
          style={{ width: '1%' }}
          header="Action"
          body={(rd) => (
            <div className="p-d-flex gap-1by2 p-jc-start">
              <Link to={`?id=${rd?.id}`}>
                <Button
                  icon="pi pi-eye"
                  tooltip="Open Record"
                  tooltipOptions={{
                    position: 'bottom',
                  }}
                />
              </Link>

              <Link to={`/customer/blacklist?history=${rd.id}`}>
                <Button
                  tooltip="History"
                  tooltipOptions={{ position: 'bottom' }}
                  icon="pi pi-clock"
                  className="p-button p-button-help"
                />
              </Link>
            </div>
          )}
        />
      </DataTable>

      <ChangeHistorySidebar
        setTableLoading={setTableLoading}
        header="Customer Blacklist Change History"
        historyType="customer-blacklist"
      />
    </div>
  )
}
